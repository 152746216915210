<template>
    <div class="pa-1 mx-1 rounded d-flex" :class="{'active': selected, 'white': !selected }" @click="$emit('click')" v-if="show">
        <v-avatar color="white" size="32" class="align-self-center">
            <div class="product-img" :style="{backgroundImage: `url(${productImgUrl})`}"></div>
        </v-avatar>
        <div class="product-infos px-2">
            <span class="font-weight-bold product-name">{{ capitalize(translateName(product.name)) }}</span>
            <div class="d-flex align-center">
                <span class="font-weight-bold primary--text text-body-2">{{ formatPrice(product.price * qty) }}</span>
                <span class="primary--text text-caption ml-1">({{ formatPrice(product.price) }}/{{ $t("unit") }})</span>
            </div>
        </div>

        <v-spacer></v-spacer>

        <div class="mr-5 product-qty">
            <span class="primary--text">x{{ qty }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
        qty: Number,
        selected: Boolean
    },
    data() {
        return {
            show: true,
            selectedQty: 0
        }
    },
    computed: {
        productImgUrl() {
            return this.config.WS_URL + "/catalog/product/image/" + this.product.image
        },
        qtyToDelete(){
            return Math.abs(this.selectedQty - this.qty)
        }
    },
    methods: {

    }
}
</script>

<style scoped>


.active{
    outline: 2px solid var(--color-primary) !important;
    background-color: #005a8c24 !important;
}

.product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-img {
    width: 76px;
    height: 76px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.product-infos {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}


.product-qty {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

</style>
