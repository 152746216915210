<template>
    <div class="px-3 py-4 order-details-wrapper">
        <div ref="header">
            <div class="d-flex align-center">
                <v-btn icon @click="$router.push({name: 'Orders'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
                <!-- <v-spacer></v-spacer> -->
                <p class="mb-0 font-weight-medium text-body-1 primary--text d-flex align-center" :class="{'error--text': this.orderComment && this.orderComment.reversed, 'warning--text': this.orderComment && this.orderComment.refund}">
                    <span>Order N°{{orderToCheck.orderNumber}} </span>
                    <span v-if="this.orderComment" class="ml-2 grey--text text-caption">(Order <span v-if="this.orderComment.refund">refunded</span> <span v-else-if="this.orderComment.reversed">reversed</span>)</span> </p>
                <v-spacer></v-spacer>
            </div>
            <div class="my-2 full-width font-weight-medium text-body-2 primary--text d-flex align-center justify-center order-details-header">
                <div class="rounded overlay" :style="{backgroundColor: '#FAFAFA'}" ></div>
                <div class="my-2 mx-1 d-flex actions align-center flex-column">
                    <v-btn class=" rounded-lg" elevation="0" outlined color="#E3EDF2" @click="handleCheckBtnClick('invalid')" large>
                        <v-img width="32" height="32" src="@/assets/img/icon-cross.png" contain></v-img>
                    </v-btn>
                    <span class="text-caption">{{$t("orderDetailsInvalid")}}</span>
                </div>
                <div class="my-2 mx-1 d-flex actions align-center flex-column">
                    <v-btn class=" rounded-lg" elevation="0" outlined color="#E3EDF2" @click="handleCheckBtnClick('valid')" large>
                        <v-img width="32" height="32" src="@/assets/img/icon-check.png" contain></v-img>
                    </v-btn>
                    <span class="text-caption">{{$t("orderDetailsValid")}}</span>
                </div>
                <div class="my-2 mx-1 d-flex actions align-center flex-column">
                    <v-btn class=" rounded-lg" elevation="0" outlined color="#E3EDF2" @click="handleCheckBtnClick('check')" large>
                        <v-img width="32" height="32" src="@/assets/img/icon-barcode.png" contain></v-img>
                    </v-btn>
                    <span class="text-caption">{{$t("orderDetailsCheck")}}</span>
                </div>
                <div v-if="this.orderComment && this.orderComment.paymentReference && !this.orderComment.refund && !this.orderComment.reversed" class="my-2 mx-1 d-flex actions align-center flex-column">
                    <v-btn v-if="isFirstOrder == false" class=" rounded-lg" elevation="0" outlined color="#E3EDF2" @click="showRefundPaymentDialog = true" large>
                        <v-img width="32" height="32" src="@/assets/img/icon-refund.png" contain></v-img>
                    </v-btn>
                    <v-btn v-if="isFirstOrder == true" class=" rounded-lg" elevation="0" outlined color="#E3EDF2" @click="reversePayment()" large>
                        <v-img width="32" height="32" src="@/assets/img/icon-refund.png" contain></v-img>
                    </v-btn>
                    <span class="text-caption text-center">{{$t("orderDetailsRefund")}}</span>
                </div>
               
            </div>
        </div>
        <div class="order-details-body" :style="{'max-height': `calc(calc(var(--vh, 1vh) * 100) - ${footerHeight}px - ${headerHeight}px - ${componentHeaderHeight + 16}px)`}">
            <div class="products-list-wrapper pb-8 full-width">
                <div v-for="(basketProduct, key) in orderToCheck.basketProducts" :key="`order-product-${key}`" @click="handleClick(key)" class="full-width rounded-lg my-2 px-3 py-2 d-flex flex-column align-center product-wrapper">
                    <div class="full-width d-flex align-center">  
                        <v-avatar :tile="selectedProductId == key" class="align-self-start">
                            <img :src="productImgUrl(basketProduct.product)" alt="John" >
                        </v-avatar>
                        <div class="d-flex flex-column product-infos full-width">
                            <div class="ml-2 d-flex align-center justify-space-between product-infos">
                                <span class="text-caption font-weight-medium product-name" :class="{'warning--text text-decoration-line-through': isProductRefunded(basketProduct.id) }">{{translateName(basketProduct.product.name)}} </span>
                                <span class="grey--text text-caption font-weight-light font-italic" v-if="isProductRefunded(basketProduct.id)">(Refunded)</span>
                                <v-spacer></v-spacer>
                                <span class="text-caption">{{formatPrice(basketProduct.product.price)}}</span>
                            </div>
                            <div v-if="selectedProductId == key" class="ml-2 d-flex align-center product-infos">
                                <span class="text-caption product-name">{{$t("orderDetailsQty")}}</span>
                                <v-spacer></v-spacer>
                                <span class="text-caption">{{basketProduct.qty}}</span>
                            </div>
                            <div v-if="selectedProductId == key" class="ml-2 d-flex align-center product-infos">
                                <span class="text-caption ">{{$t("orderDetailsDescription")}}</span>
                                <v-spacer></v-spacer>
                                <span class="ml-4 text-caption product-desc">{{translateName(basketProduct.product.description)}}</span>
                            </div>
                        </div>
                    </div>
                    <v-icon>{{selectedProductId == key ? "mdi-chevron-up" : "mdi-chevron-down"}}</v-icon>
                </div>
            </div>
        </div>
        <div ref="footer" class="py-3 full-width white order-detail-footer">
            <v-divider class="grey mx-2 mb-2"></v-divider>
            <div class="py-2 d-flex flex-column order-info-wrapper full-width">
                <p class="ma-0 mx-2 text-caption font-weight-bold">{{$t("orderDetailsRecapTitle")}}</p>
                <div class="mx-2 my-1 d-flex align-center product-infos">
                    <span class="text-caption ">{{$t("orderDetailsRecapNbProucts")}}</span>
                    <v-spacer></v-spacer>
                    <span class="text-caption product-name">{{totalNbProducts}}</span>
                </div>
                <div class="mx-2 my-1 d-flex align-center product-infos">
                    <span class="text-caption ">{{$t("orderDetailsRecapSubTotal")}}</span>
                    <v-spacer></v-spacer>
                    <span class="text-caption product-name">{{formatPrice(orderToCheck.totalAmount * 0.8)}}</span>
                </div>
                <div class="mx-2 my-1 d-flex align-center product-infos">
                    <span class="text-caption ">{{$t("orderDetailsRecapTotalTVA")}}</span>
                    <v-spacer></v-spacer>
                    <span class="ml-4 text-caption product-name">{{formatPrice(orderToCheck.totalAmount * 0.2)}}</span>
                </div>
                <div class="mx-2 my-1 d-flex align-center product-infos">
                    <span class="text-caption font-weight-bold ">{{$t("orderDetailsRecapTotal")}}</span>
                    <v-spacer></v-spacer>
                    <span class="text-caption font-weight-bold product-name">{{formatPrice(orderToCheck.totalAmount)}}</span>
                </div>
                <div v-if="this.orderComment && this.orderComment.refundAmount" class="warning--text mx-2 my-1 d-flex align-center product-infos">
                    <span class="text-caption font-weight-bold product-name">Refund</span>
                    <v-spacer></v-spacer>
                    <span class="ml-4 text-caption font-weight-bold product-name">-{{formatPrice(this.orderComment.refundAmount)}}</span>
                </div>
            </div>
        </div>
        <v-dialog v-model="showOrderValidationStatusDialog">
            <OrderValidationStatusDialog :validOrder="validOrder" />
        </v-dialog>
        <v-dialog v-model="showRefundPaymentDialog" persistent>
            <RefundPaymentDialog :orderProducts="orderToCheck.basketProducts" @refund="refundPayment($event)" @close="showRefundPaymentDialog = false" :key="`refund-dialog-${showRefundPaymentDialog}`"/>
        </v-dialog>
    </div>
</template>

<script>
    import OrderValidationStatusDialog from "@/components/OrderValidationStatusDialog.vue"
    import RefundPaymentDialog from "@/components/RefundPaymentDialog.vue"
    import {pageInfoMixin} from "@/mixins/pageInfoMixin"
    import {mapState} from 'vuex'
    var Utils = require("@/assets/js/Utils");

    import { changeBasketComment } from "@/services/BasketService"

    export default {
        components: { OrderValidationStatusDialog, RefundPaymentDialog },
        mixins: [pageInfoMixin],
        data() {
            return {
                selectedProductId: -1,
                footerHeight: 0,
                componentHeaderHeight: 0,
                validOrder: false,
                showOrderValidationStatusDialog: false,
                showRefundPaymentDialog: false,
                isFirstOrder: false
            }
        },
        mounted () {
            this.footerHeight = this.$refs.footer.offsetHeight
            this.componentHeaderHeight = this.$refs.header.offsetHeight
        },
        async created () {
            console.log('DLN routeParams',this.$route.params);
            if(this.$route.params.orderComplexId){
                if(this.$route.query.keyIndex == 0 ){
                    this.isFirstOrder = true
                }else{
                    this.isFirstOrder = false
                }
                await this.getBasketByComplexId(this.$route.params.orderComplexId);
                let queryParams = this.getQueryParams()
                if(queryParams.data ){
                    let parsedQueryParams = JSON.parse(queryParams.data)
                    console.log('DLN data',parsedQueryParams);
                    if(parsedQueryParams.result == "WPI_RESULT_SUCCESS"){
                        let refundInfo = JSON.parse(sessionStorage.getItem("REFUND_INFO"))
                        let comment = {...refundInfo, ...{refund: true}}
                        await changeBasketComment(this.$route.params.orderComplexId, JSON.stringify(comment))
                        sessionStorage.removeItem("REFUND_INFO")
                        let query = Object.assign({}, this.$route.query);
                        delete query.status;
                        delete query.reference;
                        this.$router.replace({ query });

                        this.showSnackbar({
                            message: this.$t("orderDetailsRefundSuccessSnackBar"),
                            type: "success",
                            timeout: 10
                        })
                    }else if(parsedQueryParams.result == "WPI_RESULT_FAILURE"){
                        this.showSnackbar({
                            message: this.$t("orderDetailsRefundErrorSnackBar"),
                            type: "error",
                            timeout: 10
                        })
                    }
                }
            }else{
                this.$router.push({name: "Orders"})
            }
        },
        methods: {
                
            productImgUrl(product){
                return this.config.WS_URL + "/catalog/product/image/" + product.image
            },
            handleClick(key){
                if(this.selectedProductId == key){
                    this.selectedProductId = -1
                }else{
                    this.selectedProductId = key
                }
            },
            handleCheckBtnClick(action){
                switch (action) {
                    case "check":
                        this.$router.push({name: "CheckProducts"})
                        break;
                    case "valid":
                        this.validOrder = true
                        this.showOrderValidationStatusDialog = true
                        break;
                    case "invalid":
                        this.validOrder = false
                        this.showOrderValidationStatusDialog = true
                        break;
                }
            },
            async refundPayment({amount, productsToRefund}){
                this.showRefundPaymentDialog = false
                sessionStorage.setItem("REFUND_INFO", JSON.stringify({...this.orderComment, ...{refundAmount: amount, refundedProducts: productsToRefund} }))
                this.startTapOnMobile(amount)
            },
            startTapOnMobile(amount){
                this.showLoader({
                    message: this.$t("scanTerminalLoaderText"),
                    color: this.$vuetify.theme.themes.light.primary
                })
                let returnUrl = window.location.origin + `/order/details/${this.$route.params.orderComplexId}`
                const request = JSON.stringify({
                    currency: "EUR",
                    requestedAmount: amount*100,
                    paymentSolutionReference: this.orderComment.paymentReference
                });
                var formatedParams = Utils.formatURI(`WPI_SERVICE_TYPE=WPI_SVC_REFUND&WPI_REQUEST=${request}&WPI_SERVICE_VERSION=2.1&WPI_SERVICE_SENDER=${returnUrl}&key=12FB4ACF031`)
                const finalUrl = `wltaponmobile://wpi.worldline?${formatedParams}`
                console.log('DLN formated params refund',formatedParams);
                console.log('DLN final url refund',finalUrl);
                window.location.replace(finalUrl)
            },
            reversePayment(){
                this.startTapOnMobileWithReverse()
            },
            startTapOnMobileWithReverse(){
                this.showLoader({
                    message: this.$t("scanTerminalLoaderText"),
                    color: this.$vuetify.theme.themes.light.primary
                })
                let returnUrl = window.location.origin + `/order/details/${this.$route.params.orderComplexId}`
                var formatedParams = Utils.formatURI(`WPI_SERVICE_TYPE=WPI_SVC_CANCEL_PAYMENT&WPI_REQUEST={"paymentSolutionReference":${this.orderComment.paymentReference}}&WPI_SERVICE_VERSION=2.1&WPI_SERVICE_SENDER=${returnUrl}&key=12FB4ACF031`)
                const finalUrl = `wltaponmobile://wpi.worldline?${formatedParams}`
                console.log('DLN formated params ticket refund',formatedParams);
                console.log('DLN final url ticket',finalUrl);
                window.location.replace(finalUrl)
            },
            isProductRefunded(basketProductId){
                if(this.orderComment && this.orderComment.refundedProducts){
                    return this.orderComment.refundedProducts.includes(basketProductId)
                }
                return false
            },
            getQueryParams(){
                const params = {};
                const queryString = window.location.search.substring(1);
                console.log('DLN url',window.location.search);
                const pairs = queryString.split("&");
                for (const pair of pairs) {
                    const [key, value] = pair.split("=");
                    params[decodeURIComponent(key)] = decodeURIComponent(value);
                }
                return params;
            }
        },
        computed:{
            ...mapState(['standalone']),
            totalNbProducts(){
                return this.orderToCheck.basketProducts.reduce((acc, basketProduct) => basketProduct.qty + acc, 0)
            },
            orderComment(){
                if(this.orderToCheck.comment != ""){
                    return JSON.parse(this.orderToCheck.comment)
                }
                return null
            }
        }
    }
</script>

<style lang="scss" scoped>
    .order-info-wrapper{
        // position: fixed;
    }
    .products-list-wrapper{
        overflow-y: scroll;
    }
    .order-details-body{
        overflow: scroll;
    }
    .order-details-header .overlay {
        width: 90%;
        height: 50%;
        position: absolute;
        z-index: 0;
        bottom: 0;
    }
    .order-details-header{
        position: relative;
    }
    .product-wrapper{
        border: solid 1px #80808040;
    }
    .product-wrapper .product-name{
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .product-wrapper .product-desc{
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .order-detail-footer{
        position: fixed;
        bottom: 0;
        left: 0;
    }
    .actions{
        z-index: 1;
    }
</style>