<template>
    <v-card class="pa-1 px-3 d-flex flex-column">
        <v-card-title class="font-weight-bold align-self-center text-center primary--text text-body-1 pb-2">
            {{$t("refundDialogTitle")}} 
        </v-card-title>
        <v-checkbox v-model="refundAllOrder" @change="selectAllProducts()" :label="$t('refundDialogRefundAllLabel')" hide-details></v-checkbox>
        <v-divider class="my-2 mt-4"></v-divider>
        <div class="full-width order-products-wrapper">
            <v-item-group v-model="selectedProducts" multiple>
                <v-item  v-for="basketProduct in orderProducts" :key="`refund-product-bloc-${basketProduct.id}`" :value="basketProduct.id" v-slot="{ active, toggle }">
                    <RefundPaymentDialogProductBloc class="my-2" :product="basketProduct.product" @click="selectBasketProduct(toggle)" :selected="active" :qty="basketProduct.qty" />
                </v-item>
            </v-item-group>
        </div>
        <div class="full-width d-flex align-center">
            <v-spacer></v-spacer>
            <p class="text-boyd-1 font-weight-bold ">{{$t("refundDialogAmountToRefund")}} <span class="primary--text">{{formatPrice(amountToRefund)}}</span></p>
        </div>
        <v-card-actions class="pt-0">
          <v-btn color="primary accent-4" small outlined @click="$emit('close')">{{$t("refundDialogCancelBtnLabel")}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary accent-4" :disabled="amountToRefund == 0" small @click="$emit('refund', {amount: amountToRefund, productsToRefund: selectedProducts})">{{$t("refundDialogRefundBtnLabel", {amount: formatPrice(amountToRefund)})}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import RefundPaymentDialogProductBloc from "./RefundPaymentDialogProductBloc.vue"
    export default {
        components: { RefundPaymentDialogProductBloc },
        props: {
            orderProducts: {
                type: Array
            },
        },
        data() {
            return {
                partial: false,
                selectedProducts: [],
                refundAllOrder: false
            }
        },
        methods: {
            selectBasketProduct(callback) {
                callback()
            },
            selectAllProducts(){
                if(this.refundAllOrder){
                    this.selectedProducts = this.orderProducts.map(orderProduct => orderProduct.id)
                }else{
                    this.selectedProducts = []
                }
            }
        },
        computed: {
            amountToRefund() {
                return this.orderProducts.reduce((acc, op) => {
                    if(this.selectedProducts.indexOf(op.id) > -1){
                        return acc + op.qty * op.product.price
                    }
                    return acc
                }, 0) 
            }
        },
        watch: {
            selectedProducts() {
                if(this.selectedProducts.length < this.orderProducts.length){
                    this.refundAllOrder = false
                }else{
                    this.refundAllOrder = true
                }
            }
        },
    }
</script>

<style scoped>
    .order-products-wrapper{
        max-height: 55vh;
        overflow-y: scroll;
    }
</style>
